<template>
  <img class="background-image" src="./assets/bg.png" alt="background" />
</template>

<script>
export default {
  methods: {
    isWeChat() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        console.log("isWeChat", true);
        return true;
      } else {
        console.log("isWeChat", false);
        window.location.href = "/download/index.html";
        return false;
      }
    },
  },
  mounted() {
    this.isWeChat();
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
.background-image {
  width: 100%;
  object-fit: cover;
  pointer-events: none;
  display: block;
  position: absolute;
  z-index: -1;
}
</style>
